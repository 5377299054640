<template>
  <div class="home">
    <!-- Buttons area -->
<!--    <section-->
<!--      v-if="!isLogged"-->
<!--      class="buttons-area"-->
<!--    >-->
<!--      <b-container>-->
<!--        <b-row>-->
<!--          <b-col cols="12">-->
<!--            <button-form-->
<!--              component-mode="outline"-->
<!--              button-class="me-2"-->
<!--              :rounded="true"-->
<!--              @action="$router.push({ name: loginRouter.name })"-->
<!--            >-->
<!--              <small class="titles-color">Login</small>-->
<!--            </button-form>-->

<!--            <button-form-->
<!--              :rounded="true"-->
<!--              @action="$router.push({ name: newCustomerRouter.name })"-->
<!--            >-->
<!--              <small class="titles-color">Cadastrar-se</small>-->
<!--            </button-form>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-container>-->
<!--    </section>-->

    <!--  Welcome area  -->
    <section class="welcome-area">
      <b-container>
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="8"
          >
            <h2 class="h2-titles">
              Bem vindo à Lalak
            </h2>

            <p>{{ getAbout }}</p>

            <button-form
              :rounded="true"
              :height="40"
              @action="$router.push({ name: aboutRouter.name })"
            >
              Saber mais sobre nós
            </button-form>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <span class="img-home">
              <img
                :src="imgHome"
                alt=""
              >
            </span>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!--  Highlight area  -->
    <section class="highlight-area">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 class="h2-titles">
              Produtos em destaque
            </h2>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <div
              v-if="loading"
              class="spinner-area"
            >
              <b-spinner />
            </div>

            <div class="carousel-products">
              <card-carousel-slide
                v-if="!loading"
                :carousel-data="products"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import {
  BContainer,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import { getHighlightProducts } from '@/utils/requests/products'
import imgHome from '@/assets/img/main/kerley.jpg'
import { newCustomerRouter, loginRouter, aboutRouter } from '@/router/routePathName'
import ButtonForm from '@/components/Buttons/ButtonForm.vue'
import CardCarouselSlide from '@/components/CarouselProducts/CardCarouselSlide.vue'
import {findHighlightProducts} from "@/utils/data/products";

export default {
  name: 'Home',

  components: {
    BContainer,
    BRow,
    BCol,
    BSpinner,
    CardCarouselSlide,
    ButtonForm,
  },

  data() {
    return {
      imgHome,
      newCustomerRouter,
      loginRouter,
      aboutRouter,

      loading: false,

      products: [],
    }
  },

  computed: {
    isLogged() {
      return this.$store.getters['sessions/isLoggedIn']
    },
    getAbout() {
      return this.$store.state.system.systemData.about
    },
  },

  mounted() {
    this.handleGetHighlightProducts()
  },

  methods: {
    async handleGetHighlightProducts() {
      this.products = findHighlightProducts()
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>
